const Spinner = () =>{
    return (
        <div id="spinner" className = "spinner">

                <div className="dot-windmill spinner-child" id="spinner-child"></div>
                
        </div>
    );
}


export default Spinner;